import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css']
})
export class BodyComponent implements OnInit {

	jobListsNumber = [1, 2, 3, 4, 5 ,6, 7, 8, 9, 10];
	// jobListsNumber = [1];
  
  constructor(private router: Router) { }

  ngOnInit() {

  }



  redirectJobDetails(pagename:string, parameter:string)
  {
    this.router.navigate([pagename, parameter]);
  }

}
